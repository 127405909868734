/* ./src/index.css */
@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  font-family: "Montserrat", sans-serif;
  /* user-select: none; */
  @apply tracking-tight;
}

.z-top {
  z-index: 9999;
}
.z-top2 {
  z-index: 9998;
}
@media (min-width: 768px) {
  .screen-h {
    height: calc(100vh - 100px);
  }
}
iframe {
  display: none !important;
}

.h-fullscreen {
  min-height: calc(100vh - 90px);
}
.full-size {
  @apply h-full w-full;
}

.btn:focus-visible,
.btn:focus,
.btn:focus-within {
  border: 1px solid #000;
}
.nav-container {
  height: 96px;
}
.nav-container.active {
  height: auto;
}

.nav-container.active .nav-item,
.nav-container.active .nav-footer {
  visibility: visible;
  display: flex;
}
.nav-container .nav-item,
.nav-container .nav-footer {
  visibility: hidden;
  display: none;
}
/* #sideBarContainer.active {
  transform: translateX(0%);
} */
/* #sideBarContainer.active > div {
  transition: opacity 200ms ease-out;
  opacity: 1;
}
#sideBarContainer > div {
  transition: opacity 200ms ease-out;
  opacity: 0;
} */
/* #sideBarContainer {
  transition: all 200ms ease-out;
  transform: translateX(-150%);
  transform-origin: left;
} */

@media only screen and (min-width: 600px) {
  .nav-container {
    height: 90px;
  }
  .nav-container .nav-item,
  .nav-container .nav-footer {
    visibility: visible;
    display: flex;
  }
}

.player-wrapper {
  position: relative;
  /* padding-top: 56.25%; Player ratio: 100 / (1280 / 720) */
}

.react-player {
  position: absolute;
  top: 0;
  left: 0;
}
.input-file {
  width: 0;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}
.truncate,
.truncate > span {
  text-overflow: ellipsis;
}

.react-tiny-popover-container {
  z-index: 99;
}

/* Inbox related CSS */

.home_container {
  position: relative;
  display: grid;
  grid-template-columns: 1fr 3fr;
  overflow: hidden;
  height: calc(100vh - 70px);
  width: 100vw;
}
.users_container {
  margin-top: 10px;
  border-right: 2px solid var(--color-6);
  overflow-y: auto;
}
.messages_container {
  position: relative;
  width: 100%;
}
.messages_user {
  padding: 10px;
  text-align: center;
  border-bottom: 2px solid var(--color-6);
}
.no_conv {
  font-size: 20px;
  color: var(--color-4);
  text-align: center;
}
.messages {
  max-height: calc(100vh - 200px);
  overflow-y: auto;
  border-bottom: 1px solid var(--color-6);
}
.user_wrapper {
  margin-bottom: 10px;
  padding: 10px;
  cursor: pointer;
}
.user_info {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.user_detail {
  display: flex;
  align-items: center;
}

.avatar {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 1px solid var(--color-4);
}
.user_status {
  width: 10px;
  height: 10px;
  border-radius: 50%;
}
.online {
  background: #34eb52;
}
.offline {
  background: var(--color-5);
}
.truncate {
  font-size: 14px;
  white-space: nowrap;
  width: 90%;
  overflow: hidden;
  text-overflow: ellipsis;
}
.truncate strong {
  margin-right: 10px;
}
.selected_user {
  background: var(--color-6);
}
.sm_screen {
  display: none;
}
.unread {
  margin-left: 10px;
  background: var(--color-3);
  color: white;
  padding: 2px 4px;
  border-radius: 10px;
}

@media screen and (max-width: 576px) {
  .home_container {
    grid-template-columns: 1fr 5fr;
  }
  .user_wrapper {
    display: none;
  }
  .sm_container {
    padding: 10px 0px;
    text-align: center;
    cursor: pointer;
  }
  .sm_screen {
    display: inline-block;
  }
  .message_form input {
    width: 50vw;
    margin: 0px 10px;
  }
  .message_form .btn {
    margin: 0px;
  }

  .message_wrapper p {
    max-width: 100%;
  }
}

.map_hover {
  cursor: pointer;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

/* .messages_container {
  position: relative;
  width: 100%;
}
.messages_user {
  padding: 10px;
  text-align: center;
  border-bottom: 2px solid var(--color-6);
}
.no_conv {
  font-size: 20px;
  color: var(--color-4);
  text-align: center;
}
.message_form {
  position: absolute;
  bottom: 0;
  left: 20%;
  width: 100%;
  height: 30px;
  display: flex;
  align-items: center;
}
.message_form input {
  width: 40vw;
  margin: 0px 10px 10px;
  padding: 10px;
  border-radius: 5px;
  outline: none;
  border: none;
}
.message_form .btn {
  margin-top: -10px;
}
.messages {
  height: calc(100vh - 200px);
  overflow-y: auto;
  border-bottom: 1px solid var(--color-6);
}
.message_wrapper {
  margin-top: 5px;
  padding: 0px 5px;
}
.message_wrapper img {
  width: 100%;
  border-radius: 5px;
}
.message_wrapper p {
  padding: 10px;
  display: inline-block;
  max-width: 50%;
  text-align: left;
  border-radius: 5px;
}
.message_wrapper small {
  display: inline-block;
  margin-top: 15px;
  opacity: 0.8;
}
.message_wrapper.own {
  text-align: right;
}
.me {
  background: var(--color-3);
  color: white;
}
.friend {
  background: var(--color-6);
}
.selected_user {
  background: var(--color-6);
}
.truncate {
  font-size: 14px;
  white-space: nowrap;
  width: 90%;
  overflow: hidden;
  text-overflow: ellipsis;
}
.truncate strong {
  margin-right: 10px;
}
.unread {
  margin-left: 10px;
  background: var(--color-3);
  color: white;
  padding: 2px 4px;
  border-radius: 10px;
}
.sm_screen {
  display: none;
} */

video {
  object-fit: cover;
}
.slick-list,
.slick-track,
.slick-slide,
.slick-slide > div {
  height: 100%;
}

.slick-arrow::before {
  color: rgba(37, 99, 235, 1) !important;
  position: absolute;
}
.slick-dots {
  bottom: 4rem !important;
}
.slick-dots li button::before {
  color: rgba(37, 99, 235, 1) !important;
}

.slider {
  max-width:100%;
  margin: auto;
  --slide-height: 38rem;
  --slide-spacing: 1rem;
  --slide-size: 100%;
}

.slider__container {
  backface-visibility: hidden;
  display: flex;
  touch-action: pan-y;
  margin-top: calc(var(--slide-spacing) * -1);
  height: calc(var(--slide-spacing) + var(--slide-height));
}
.slider__slide {
  flex: 0 0 var(--slide-size);
  min-height: 0;
  padding-top: var(--slide-spacing);
}





